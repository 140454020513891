/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {PropsWithChildren} from 'react';
import {usePutValidationResolve} from '../../hooks/use-put-validation-resolve';
import {modalsRoot} from '../../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {
	GroeneveldImportCompartmentHefSchuifMappingValidation,
	GroeneveldImportCompartmentLayoutMappingValidation,
	GroeneveldImportCompartmentProfileMissingValidation, GroeneveldImportCompartmentProfileSuggestionValidation,
	IgnoreImportValidationCommand,
	ResolveHefSchuifSchemaImportValidationMappingCommand,
	ResolveImportValidationCommandType,
} from '../../../../modules/api-client/generated';
import {ValidationResolverProps} from '../../pages/ValidationResolverPage';
import {Form, Formik, FormikHelpers} from 'formik';
import SpinnerButton from '../../../components/SpinnerButton';
import {
	HefSchuifSchemaPickerField
} from "../../../../backoffice/modules/pim/compartment-layout-type/HefSchuifSchemaPicker";

type FormValues = {compartmentLayoutTypeId: string};
export type HefSchuifSchemaResolverProps = Omit<ValidationResolverProps, 'validation'> & {
	validation: GroeneveldImportCompartmentHefSchuifMappingValidation
};
const HefSchuifSchemaResolver = ({show, validation, handleClose, children}: PropsWithChildren<HefSchuifSchemaResolverProps>) => {
	var resolveMutation = usePutValidationResolve();

	const initialFormValues: FormValues = {compartmentLayoutTypeId: ''};

	const resolve = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		return new Promise<void>((resolve, reject) => {
			var command: ResolveHefSchuifSchemaImportValidationMappingCommand = {
				commandType: ResolveImportValidationCommandType.ResolveHefSchuifSchemaImportValidationMappingCommand,
				compartmentLayoutTypeId: values.compartmentLayoutTypeId!,
				applyToAll:false,
				id: validation.id,
				importType: validation.type,
			};
			resolveMutation.mutate(command, {
				onSuccess: (model) => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	const ignore = async () => {

		var command: IgnoreImportValidationCommand = {
			commandType: ResolveImportValidationCommandType.IgnoreImportValidationCommand,
			id: validation.id,
			importType: validation.type,
		};

		await resolveMutation.mutateAsync(command);

		handleClose();
	};

	return (
		<Formik initialValues={initialFormValues} onSubmit={resolve}>
			{({values, getFieldProps, isSubmitting}) => (
				<Modal
					container={modalsRoot}
					tabIndex={-1}
					aria-hidden="true"
					autoFocus={true}
					className=""
					dialogClassName={'modal-dialog-centered'}
					contentClassName={'shadow-lg'}
					show={show}
					size="lg"
					onHide={handleClose}
					animation={false}
					backdrop={'static'}
				>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Selecteer hefschuifschema</Modal.Title>
						</Modal.Header>

						<Modal.Body className="flex-fill">
							<span className={'fw-bold'}>{validation.message} </span>
							<div className="row my-5">
								<div className={'col-4'}>
									<label className="fw-bold fs-6 mb-2">Schema</label>
								</div>
								<div className={'col'}>
									<HefSchuifSchemaPickerField name={'compartmentLayoutTypeId'} sillId={validation.sillId}/>
								</div>
							</div>
						</Modal.Body>

						<Modal.Footer>
							<Button variant="link" className="mx-4" onClick={handleClose}>
								Sluit
							</Button>
							<Button
								className="btn-light-danger mx-4"
								onClick={() => {
									ignore().then();
								}}
							>
								Negeer
							</Button>
							<SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting} disabled={isSubmitting}>
								Toepassen
							</SpinnerButton>
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export {HefSchuifSchemaResolver};
