/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {PropsWithChildren} from 'react';
import {usePutValidationResolve} from '../../hooks/use-put-validation-resolve';
import {modalsRoot} from '../../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {IgnoreImportValidationCommand, ImportMappingValidation, ImportType, ResolveImportValidationCommandType, ResolveSluitpotImportValidationMappingCommand} from '../../../../modules/api-client/generated';
import {SluitpotBeslagPicker} from '../../../../backoffice/modules/pim/sluitpotbeslag/SluitpotBeslagPicker';
import {Form, Formik, FormikHelpers} from 'formik';
import clsx from 'clsx';
import SpinnerButton from '../../../components/SpinnerButton';
import {ValidationResolverProps} from '../../pages/ValidationResolverPage';

type FormValues = {
	saveForCustomer: boolean;
	applyToAll: boolean;
	sluitpotBeslagId: string;
};

export type SluitpotResolverProps = Omit<ValidationResolverProps, 'validation'> & {
    validation: ImportMappingValidation;    
};

const SluitpotResolver = ({show, validation, handleClose}: PropsWithChildren<SluitpotResolverProps>) => {
	var resolveMutation = usePutValidationResolve();

	const initialFormValues: FormValues = {
		sluitpotBeslagId: '',
		saveForCustomer: validation.externalCode ? true : false,
		applyToAll: validation.externalCode ? true : false,
	};

	const resolve = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		return new Promise<void>((resolve, reject) => {
			var command: ResolveSluitpotImportValidationMappingCommand = {
				externalCode:validation.externalCode,
				sluitpotBeslagId: values.sluitpotBeslagId!,
				saveForCustomer: values.saveForCustomer,
				commandType: ResolveImportValidationCommandType.ResolveSluitpotImportValidationMappingCommand,
				applyToAll: values.applyToAll,
				id: validation.id,
				importType: validation.type,
			};
			resolveMutation.mutate(command, {
				onSuccess: (model) => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};
	const ignore = async () => {
		var command: IgnoreImportValidationCommand = {
			commandType: ResolveImportValidationCommandType.IgnoreImportValidationCommand,
			id: validation.id,
			importType: validation.type,
		};
		await resolveMutation.mutateAsync(command);
		handleClose();
	};

	return (
		<Formik initialValues={initialFormValues} onSubmit={resolve}>
			{({values, getFieldProps, isSubmitting}) => (
				<Modal
					container={modalsRoot}
					tabIndex={-1}
					aria-hidden="true"
					autoFocus={true}
					className=""
					dialogClassName={'modal-dialog-centered'}
					contentClassName={'shadow-lg'}
					show={show}
					size="lg"
					onHide={handleClose}
					animation={false}
					backdrop={'static'}
				>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Selecteer een sluitpot</Modal.Title>
						</Modal.Header>
						<Modal.Body className="flex-fill">
							<span className={'fw-bold'}>{validation.message} </span>
							<div className="row my-5">
								<div className={'col-4'}>
									<label className="fw-bold fs-6 mb-2">EDS sluitpot</label>
								</div>
								<div className={'col'}>
									<SluitpotBeslagPicker name={'sluitpotBeslagId'} onlyActive={true} />
								</div>
							</div>
							<div className="row mb-5">
								<div className={'col-4'}>
									<label className="fw-bold fs-6 mb-2"></label>
								</div>
								<div className={'col'}>
									<div className="form-check form-switch">
										{/* begin::Input */}
										<input
											{...getFieldProps('saveForCustomer')}
											className={clsx('form-check-input')}
											type="checkbox"
											name="saveForCustomer"
											id="saveForCustomer"
											value={values.saveForCustomer ? 'true' : 'false'}
											checked={!!values.saveForCustomer}
											disabled={validation.externalCode === '' || isSubmitting}
										/>
										{/* end::Input */}

										{/* begin::Label */}
										<label className="fw-bold fs-6 mb-2 form-check-label" htmlFor="saveForCustomer">
											Keuze onthouden
										</label>
										{/* end::Label */}
									</div>
								</div>
							</div>
							<div className="row mb-5">
								<div className={'col-4'}>
									<label className="fw-bold fs-6 mb-2"></label>
								</div>
								<div className={'col'}>
									<div className="form-check form-switch">
										{/* begin::Input */}
										<input
											{...getFieldProps('applyToAll')}
											className={clsx('form-check-input')}
											type="checkbox"
											name="applyToAll"
											id="applyToAll"
											value={values.applyToAll ? 'true' : 'false'}
											checked={!!values.applyToAll}
											disabled={validation.externalCode === '' || isSubmitting}
										/>
										{/* end::Input */}

										{/* begin::Label */}
										<label className="fw-bold fs-6 mb-2 form-check-label" htmlFor="applyToAll">
											Toepassen binnen import
										</label>
										{/* end::Label */}
									</div>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="link" className="mx-4" onClick={handleClose}>
								Sluit
							</Button>
							<Button
								className="btn-light-danger mx-4"
								onClick={() => {
									ignore().then();
								}}
							>
								Negeer
							</Button>
							<SpinnerButton className={'btn btn-primary'} spinning={isSubmitting} type={'submit'}>
								Toepassen
							</SpinnerButton>
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export {SluitpotResolver};
