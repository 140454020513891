/* eslint-disable jsx-a11y/anchor-is-valid */

import {useEkosietAuth0} from "../../../modules/auth0/core/useEkosietAuth0";
import {PageTitle} from "../../../../_metronic/layout/core";
import {EkoCard, EkoCardBody} from "../../components/card";
import {StartImportForm} from "../components/StartImportForm";
import {FilterContextProvider} from "../../context/FilterContext";
import {ImportList} from "../components/ImportList";
import {EkosietUitlegGroeneveldWidget} from "../../../customer-portal/widgets/EkosietUitlegGroeneveldWidget";
import ImportStatusProgress from "../components/ImportStatusProgress";
import {ImportStatus} from "../../../modules/api-client/generated";
import {ImportSearch} from "../components/ImportSearch";
import {Link, useLocation} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {CustomerRoutePath} from "../../../customer-portal/routing/RoutePath";
import {ImportRoutePath} from "../routes/ImportRoutes";

export function ImportsPage() {
    const {customerData, isKlant, isMedewerker} = useEkosietAuth0();
    const location = useLocation();
    const currentPath = location.pathname;
    return (
        <>
            <PageTitle
                toolbar={
                    <Link
                        to={`${currentPath}` + '/../' + `${ImportRoutePath.importMapping}`}
                        className={'btn btn-secondary'}>Import koppelingen beheren</Link>
                }
            >
                Importeren
            </PageTitle>
            <div className={'alert alert-info'}>
                <span className={'fw-bold'}>Let op:</span> De import functionaliteit is nog in ontwikkeling.
                Controleer de geïmporteerde dorpels, IEDERE HEFSCHUIF DORPEL DIENT VOORALSNOG NA DE IMPORT HANDMATIG GECONFIGUREERD TE WORDEN!
            </div>
            <ImportStatusProgress className={'my-5'} status={ImportStatus.ValidatingFile}/>
            {!customerData.data?.gaImportEnabled && !isMedewerker ? (
                    <div className={'alert alert-info'}>
                        Momenteel testen we de Groeneveld import met een klein aantal klanten. Binnenkort kunt u via deze
                        pagina orders importeren via een Groeneveld export.
                    </div>
                )
                :
                <div className={'row'}>
                    <div className={'col'}>
                        <EkoCard className={'mb-5'}>
                            <EkoCardBody>
                                <StartImportForm requireCustomer={!isKlant}/>
                            </EkoCardBody>
                        </EkoCard>
                        <EkoCard>
                            <EkoCardBody>
                                <FilterContextProvider>
                                    {isMedewerker &&
                                        <ImportSearch/>
                                    }
                                    <ImportList/>
                                </FilterContextProvider>
                            </EkoCardBody>
                        </EkoCard>
                    </div>
                    <div className={'col col-lg-4'}>
                        <EkosietUitlegGroeneveldWidget className={''}/>
                    </div>
                </div>

            }
        </>
    )
}
